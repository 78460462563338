/* eslint-disable node/prefer-global/process */
export default defineNuxtRouteMiddleware(async () => {
    const firebaseUser = await ensureFirebaseUser(true)
    if (!firebaseUser)
        return navigateTo('/problems')

    await ensureApolloInitialized(firebaseUser)

    if (process.client) {
        const user = await ensureCiviqaUserOnClient()
        if (!user)
            return navigateTo('/problems')
    }
})
